

/* We set the size of the picture */
td.cart-product-thumbnail > div {
    display: block;
    width: 64px;
    height: 95px;
}

/* We set the size of the picture */
td.cart-product-thumbnail > div img {
    height: 95px;
    width:auto;
    border: 0px;
    margin-right:auto;
    margin-left:auto;
}

/* We set default style for product name cell */
td.cart-product-name {
    font-weight: 700;
    font-size: 13px;
    color: var(--body-text-color);
}

/* We set the font size and vertical alignement of table cell */
tr.cart_item td {
    font-size: 14px;
    vertical-align: middle;
}

/* We adjust the style of the subtitle in product name */
td.cart-product-name .subtitle {
    font-weight: 500;
    font-style: italic;
}

/* We remove some space for the remove button and subtotal */
@media (max-width: 767.98px) {
tr.cart_item td.cart-product-remove, .cart td.cart-product-subtotal {
        padding:0px !important;
}
}




/* We set font size of the header */
.cart-recap th {
    font-size:14px;
}

/* We hide thumbnail for smaller viewport */
@media (max-width: 767.98px) {
.cart-recap .cart-product-thumbnail {
        display: none;
}
}

/* We hide price for smaller viewport */
@media (max-width: 575.98px) {
.cart-recap .cart-product-price {
        display: none;
}
}


